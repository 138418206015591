import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare var $;

@Component({
  selector: 'make-ai-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.css']
})
export class InstagramComponent implements OnInit {

  isShow = false;
  isError = false;
  userName: string;
  profilePictureUrl: string;
  latestPictureUrlList: string[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    const url = 'https://www.instagram.com/make_ai_official';
    this.getInstagramData(url);
  }

  getInstagramData(url) {
    this.http.get(url, { responseType: 'text' })
      .subscribe((html: string) => {
        let data = JSON.parse(html.slice(html.indexOf('{"config":'), html.indexOf('p":false}') + 9));
        data = data.entry_data.ProfilePage[0].graphql.user;

        this.userName = data.username;
        this.profilePictureUrl = data.profile_pic_url;

        for (let i = 0; i < 9; i++) {
          this.latestPictureUrlList.push(data.edge_owner_to_timeline_media.edges[i].node.display_url);
        }

        this.isShow = true;
      }, error => {
        this.isError = true;
      });
  };
}
