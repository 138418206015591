import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  ngOnInit() {
    setTimeout(() => {
      $('#loading').css({
        opacity: 0,
        transition: "1s",
      });
      setTimeout(() => {
        $('#loading').css({
          display: "none",
        })
      }, 1500);
    }, 2500);
  }
}
