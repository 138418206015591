import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'make-ai-menu-first-row',
  templateUrl: './menu-first-row.component.html',
  styleUrls: ['./menu-first-row.component.css']
})
export class MenuFirstRowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#link-to-make-ai').click(() => {
      window.location.href = 'https://make-ai.com/';
    });

    $('#link-to-minne').click(() => {
      window.location.href = 'https://minne.com/@ai-baby-kids';
    });

    $('#about').on({
      'mouseenter': () => {
        $('.overlay-about').css({
          'opacity': '1',
          'transition': '.7s'
        });
      },
      'mouseleave': () => {
        $('.overlay-about').css({
          'opacity': '0',
          'transition': '.7s'
        });
      }
    });

    $('#link-to-make-ai').on({
      'mouseenter': () => {
        $('.overlay-make-ai').css({
          'opacity': '1',
          'transition': '.7s'
        });
      },
      'mouseleave': () => {
        $('.overlay-make-ai').css({
          'opacity': '0',
          'transition': '.7s'
        });
      }
    });

    $('#link-to-minne').on({
      'mouseenter': () => {
        $('.overlay-minne').css({
          'opacity': '1',
          'transition': '.7s'
        });
      },
      'mouseleave': () => {
        $('.overlay-minne').css({
          'opacity': '0',
          'transition': '.7s'
        });
      }
    });

    $('a').on({'click': () => {
      $('html, body').animate({ scrollTop: 0});
    }});
  }

}
