import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'make-ai-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.css']
})
export class SlideShowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.runSlideShow();
  }

  runSlideShow() {
    $(document).ready(() => {
      $('#slide-show').sliderPro({
        width: 1200,
        height: 500,
        autoplay: true,
        autoplayDelay: 5000,
        arrows: true,
        slideAnimationDuration: 1000,
      })
    });
  }

}
