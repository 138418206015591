import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'make-ai-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isOpenNavSp = false;

  constructor() { }

  ngOnInit() {
    this.runResponsiveHeader();

    $('a').on({'click': () => {
      $('html, body').animate({ scrollTop: 0});
    }});
  }

  runResponsiveHeader() {
    $(document).ready(() => {
      $(window).on('load resize', () => {
        const header = $('.header');
        const addClass = 'scrolled';
        const offset = header.offset();
        const scrollY = offset.top;

        $(window).scroll(() => {
          if ($(window).scrollTop() > scrollY + 150) {
            header.addClass(addClass);
          } else {
            header.removeClass(addClass);
          }
        });
      });
    });
  };

  toggleNavigationSp() {
    if (this.isOpenNavSp) {
      $('#navigation-sp').hide();
      this.isOpenNavSp = false;
    } else {
      $('#navigation-sp').show();
      this.isOpenNavSp = true;
    }
  };
}
