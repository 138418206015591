import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'make-ai-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  selectedYear = new Date().getFullYear();
  selectedMonth = new Date().getMonth() + 1;
  selectedDay: number;
  selectedTime: string;
  dayList = new Array(7);

  constructor() { }

  ngOnInit() {
    this.createCalendar(this.selectedYear, this.selectedMonth);
  }

  init() {
    for (let i = 0; i < 6; i++) {
      this.dayList[i] = new Array(7).fill(0);
    }
  }

  createCalendar(year, month) {
    const date = new Date(year, month - 1, 1);
    let dayOfTheWeek = date.getDay();
    let days: number;

    this.init();

    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        days = 31;
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        days = 30;
        break;
      case 2:
        if (year % 400 === 0) {
          days = 29;
        } else if (year % 100 === 0) {
          days = 28;
        } else if (year % 4 === 0) {
          days = 29;
        } else {
          days = 28;
        }
        break;
    }

    let week = 1;

    for (let i = 1; i <= days; i++) {
      this.dayList[week - 1][dayOfTheWeek] = i;
      if (dayOfTheWeek === 6) {
        week++;
        dayOfTheWeek = 0;
      } else {
        dayOfTheWeek++;
      }
    }
  }

  prevMonth() {
    if (this.selectedMonth === 1) {
      this.selectedYear -= 1;
      this.selectedMonth = 12;
    } else {
      this.selectedMonth -= 1;
    }
    this.createCalendar(this.selectedYear, this.selectedMonth);
  };

  nextMonth() {
    if (this.selectedMonth === 12) {
      this.selectedYear += 1;
      this.selectedMonth = 1;
    } else {
      this.selectedMonth += 1;
    }
    this.createCalendar(this.selectedYear, this.selectedMonth);
  };

  closeCalendar() {
    $('#calendar').css({
      'display': 'none'
    });
  };

  showReservationModal(day) {
    this.selectedDay = day;
    $('#reservation').modal();
  }

  showConfirmModal(time) {
    this.selectedTime = time;
    $('.confirm-msg').show();
    $('.modal-footer').show();
    $('.completed-msg').hide();
    $('#confirm').modal();
  }

  reservation() {
    $('.confirm-msg').hide();
    $('.modal-footer').hide();
    $('.completed-msg').show();

    setTimeout(() => {
      $('#confirm').modal('hide');
    }, 2000);
  }
}
