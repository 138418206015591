import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'make-ai-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#contact').on({
      'mouseenter': () => {
        $('.overlay-contact').css({
          'opacity': '1',
          'transition': '.7s'
        });
      },
      'mouseleave': () => {
        $('.overlay-contact').css({
          'opacity': '0',
          'transition': '.7s'
        });
      }
    });

    $('#schedule').on({
      'mouseenter': () => {
        $('.overlay-schedule').css({
          'opacity': '1',
          'transition': '.7s'
        });
      },
      'mouseleave': () => {
        $('.overlay-schedule').css({
          'opacity': '0',
          'transition': '.7s'
        });
      },
      'click': () => {
        $('#calendar').css({
          'display': 'block'
        });
      }
    });

    $('a').on({'click': () => {
      $('html, body').animate({ scrollTop: 0});
    }});
  }

}
