import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { TopComponent } from './views/top/top.component';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { SlideShowComponent } from './elements/slide-show/slide-show.component';
import { MenuFirstRowComponent } from './elements/menu-first-row/menu-first-row.component';
import { MenuSecondRowComponent } from './elements/menu-second-row/menu-second-row.component';
import { LookbookComponent } from './views/lookbook/lookbook.component';
import { CollectionComponent } from './views/collection/collection.component';
import { AboutComponent } from './views/about/about.component';
import { InstagramComponent } from './elements/instagram/instagram.component';
import { ContactComponent } from './views/contact/contact.component';
import { CalendarComponent } from './elements/calendar/calendar.component';

const routes: Routes = [
  { path: '', component: TopComponent },
  { path: 'about', component: AboutComponent },
  { path: 'lookbook', component: LookbookComponent },
  { path: 'collection', component: CollectionComponent },
  { path: 'contact', component: ContactComponent },
  { path: '**', component: NotFoundComponent },
]

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TopComponent,
    NotFoundComponent,
    SlideShowComponent,
    MenuFirstRowComponent,
    MenuSecondRowComponent,
    LookbookComponent,
    CollectionComponent,
    AboutComponent,
    InstagramComponent,
    ContactComponent,
    CalendarComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
