import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'make-ai-menu-second-row',
  templateUrl: './menu-second-row.component.html',
  styleUrls: ['./menu-second-row.component.css']
})
export class MenuSecondRowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#lookbook').on({
      'mouseenter': () => {
        $('.overlay-lookbook').css({
          'opacity': '1',
          'transition': '.7s'
        });
      },
      'mouseleave': () => {
        $('.overlay-lookbook').css({
          'opacity': '0',
          'transition': '.7s'
        });
      }
    });

    $('#collection').on({
      'mouseenter': () => {
        $('.overlay-collection').css({
          'opacity': '1',
          'transition': '.7s'
        });
      },
      'mouseleave': () => {
        $('.overlay-collection').css({
          'opacity': '0',
          'transition': '.7s'
        });
      }
    });

    $('a').on({'click': () => {
      $('html, body').animate({ scrollTop: 0});
    }});
  }

}
