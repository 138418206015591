import { Component, OnInit } from '@angular/core';
import { LuminousGallery } from 'luminous-lightbox';

declare var $;

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.css']
})
export class CollectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.appendImageHTML();
    this.runLuminousLightbox();
  }

  appendImageHTML() {
    let html = '';
    const path = './assets/img/photo/2017AW/';
    const images = 20;
    const imgstyle = "width: 285px;" +
                     "height: 370px;" +
                     "object-fit: cover;" +
                     "padding: 20px 10px;";

    for (let i = 1; i <= images; i++) {
      html += '<a href="' + path + i + '.jpg" class="luminous col-md-3 col-12">' +
                '<img src="' + path + i + '.jpg" alt="" style="' + imgstyle + '">' +
              '</a>';
    }

    $('#collection-img').append(html);
  };

  runLuminousLightbox() {
    const luminous = document.querySelectorAll('.luminous');
    const caption = {
      caption: luminous => {
        return luminous.querySelector('img').getAttribute('alt');
      }
    };

    if (luminous) {
      new LuminousGallery(luminous, {}, caption);
    }
  }

}
